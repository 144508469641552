.navbar {
  background-color: #f1f9ff;
  width: 100vw;
}

a {
  cursor: pointer;
}

@media (max-width: 500px) {
  .navbar-brand img {
    width: 90px;
  }
}
