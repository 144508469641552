* {
  box-sizing: border-box;
}

body {
  font-family: "auto";
  height: 100vh;
  width: 100vw;
/* overflow-x: hidden; */
}
ul {
  list-style-type: none;
}

.row{
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.gradient-underline {
  font-family: var(--cnvs-primary-font);
  background-image: linear-gradient(
    280deg,
    red 12.08%,
    pink 53.53%,
    green 95.62%
  );
  background-repeat: no-repeat;
  background-size: 60% 0.2em;
  background-position: 0 110%;
  font-size: 2rem;
}

.hero-container {
  background: url(https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/vmware%2F320768973.jpg?alt=media);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;
  width: 100vw;
}

.hero-icon {
  /* width: 100%; */
  z-index: 9999999;
  position: absolute;
  top: 5%;
  left: 25%;
  display: none;
}

.hero-content {
  position: absolute;
  z-index: 999;
  width: 90%;
  backdrop-filter: blur(20px);
  color: white;
  padding: 20px;
}

.hero-content.first {
  top: 5%;
  left: 5%;
}

.hero-content.second {
  top: 25%;
  left: 60%;
}

.overview {
  padding: 20px 0;
  background-image: url(https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/vmware%2FOverview.png?alt=media);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  height: 115vh;
  position: relative;
  width: 100vw;
}

.ovreview-card-image-top {
  padding: 5%;
}

.overview-card {
  display: flex;
  flex-direction: row;
  column-gap: 15%;
}

.overview-overlay {
  z-index: 1;
  width: 100vw;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(241, 249, 255, 1),
    rgba(241, 249, 255, 0) 0%,
    rgba(241, 249, 255, 1)
  );
  padding: 56px 48px;
  display: none;
}

.overview-overlay-2 {
  z-index: 1;
  width: 100vw;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(241, 249, 255, 1),
    rgba(241, 249, 255, 0) 90%,
    rgba(241, 249, 255, 1)
  );
  padding: 50px 48px;
  display: none;
}

.overview-overlay-3 {
  z-index: 1;
  width: 100vw;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    to bottom,
    rgba(241, 249, 255, 1),
    rgba(241, 249, 255, 0) 12%,
    rgba(241, 249, 255, 1)
  );
  padding: 56px 48px;
  display: none;
}

.overview-overlay-4 {
  z-index: 1;
  width: 100vw;
  position: absolute;
  top: 0%;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    rgba(241, 249, 255, 1),
    rgba(241, 249, 255, 0) 50%,
    rgba(0, 0, 0, 0)
  );
  padding: 56px 48px;
  display: none;
}

.overview-overlay-5 {
  z-index: 1;
  width: 100vw;
  position: absolute;
  top: -5%;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    rgb(0, 0, 0, 0),
    rgba(241, 249, 255, 0) 0%,
    #fff
  );
  padding: 33px 48px 25px 48px;
  display: none;
}

.overview-overlay-6 {
  z-index: 1;
  width: 100vw;
  position: absolute;
  /* top: 0; */
  bottom: 28px;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    to bottom,
    #fff,
    rgba(0, 0, 0, 0) 0%,
    #fff 64%
  );
  padding: 30px 48px;
  display: none;
}

.overview-overla-7 {
  z-index: 1;
  width: 100vw;
  position: absolute;
  /* top: 0; */
  bottom: -43px;
  left: 0;
  right: 0;
  background-image: linear-gradient(
    to top,
    #fff,
    rgba(0, 0, 0, 0) 0%,
    #fff 64%
  );
  padding: 30px 48px;
  display: none;
}

.overview-header {
  width: 100%;
  text-align: center;
  font-size: 4rem;
  margin-top: 16%;
  margin-bottom: 20px;
}

.overview-left,
.overview-right {
  width: 45%;
  position: relative;
}

.overview-image {
  width: 100%;
  filter: blur(2px);
}

.overview-right {
  z-index: 1;
  margin-left: 5%;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
}

.gradient-bg {
  border: 10px solid red;
}

/* event Objectives */

.ag-format-container {
  width: 100%;
  margin: 0 auto;
}

.ag-courses_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;

  padding: 50px;
}
.ag-courses_item {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  flex-basis: calc(33.33333% - 30px);
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  margin: 0 15px 30px;

  overflow: hidden;

  border-radius: 28px;
}
.ag-courses-item_link {
  display: block;
  padding: 30px 20px;
  background-color: #fff;

  overflow: hidden;
  height: 32vh;
  position: relative;
}
.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color: #fff;
}
.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}
.ag-courses-item_title {
  min-height: 87px;

  overflow: hidden;

  font-weight: bold;
  font-size: 30px;
  color: #fff;

  z-index: 2;
  position: relative;
}
.ag-courses-item_date-box {
  font-size: 18px;
  color: #000;

  z-index: 2;
  position: relative;
}
.ag-courses-item_date {
  font-weight: bold;
  color: #f9b234;

  -webkit-transition: color 0.5s ease;
  -o-transition: color 0.5s ease;
  transition: color 0.5s ease;
}
.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background-color: #cc0a2f;

  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;

  border-radius: 50%;

  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.ag-courses_item:nth-child(2n) .ag-courses-item_bg {
  background-color: #cc0a2f;
}
.ag-courses_item:hover .ag-courses-item-title-text,
.ag-courses_item:hover .ag-courses-item_date-box {
  color: #fff !important;
}

.ag-courses_item:nth-child(3n) .ag-courses-item_bg {
  background-color: #cc0a2f;
}
.ag-courses_item:nth-child(4n) .ag-courses-item_bg {
  background-color: #cc0a2f;
}
.ag-courses_item:nth-child(5n) .ag-courses-item_bg {
  background-color: #cc0a2f;
}

.relax-container {
  background: url("https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/vmware%2FRecharge%20Relax%20Reinvent.jpg?alt=media");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: 100vw;
  height: 113vh;
}
.relax-text-designation {
  color: #000;
}
.relax-content-container {
  display: flex;
  flex-direction: column;
  /* row-gap: 35%; */
  align-items: start;
  justify-content: center;
}

.relax-header-text {
  padding: 03% 5% 0 5%;
  text-align: center;
}

.relax-img {
  padding: 0 5% 5% 5%;
}
.relax-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.relax-content {
  display: flex;
  flex-direction: row;
  margin-bottom: 10%;
}

.relax-key-text {
  color: #cc0a2f;
}
.border-bottom {
  padding-bottom: 10%;
  border-bottom: 3px solid #000 !important;
}
.relax-text {
  font-size: 1.5rem;
}

/* speaker cards */
.speakers {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/vmware%2FSpeakers%20bg.png?alt=media");
  background-size: cover;
  background-position: bottom;
  width: 100vw;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
}
.card {
  width: 100%;
  max-width: 300px;
  min-width: 200px;
  height: 280px;
  background-color: #cc0a2f;
  margin: 10px;
  border-top-right-radius: 35px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  font-size: 16px;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.icon {
  width: 100%;
  height: 50%;
  overflow: hidden;
}

.icon img {
  filter: grayscale();
  border-top-right-radius: 35px;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.card:hover img {
  transition: all 0.2s ease-out;
  transform: scale(1.1, 1.1);
  filter: none;
}

.info {
  width: 100%;
  height: 50%;
  background-color: rgb(210, 210, 210);
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.card .title {
  margin: 10px 0 5px 0;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.card .designation {
  margin: 5px 0;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 2px;
}

.card .text {
  font-size: 13px;
  font-weight: 200;
  letter-spacing: 2px;
}

.card:hover .textcolor {
  color: #cc0a2f;
  transition: all 0.2s ease-out;
}

.card:hover .designation {
  color: #cc0a2f;
  transition: all 0.2s ease-out;
}

/* Agenda */

.tabs {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  background: #e5e5e5;
  /* box-shadow: 0 48px 80px -32px rgba(0, 0, 0, 0.3); */
}

.input {
  position: absolute;
  opacity: 0;
}

.label {
  width: 100%;
  padding: 20px 30px;
  background: #e5e5e5;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  color: red;
  transition: background 0.1s, color 0.1s;
}

.label:hover {
  background: #d8d8d8;
}

.label:active {
  background: #ccc;
}

.input:focus + .label {
  z-index: 1;
}

.input:checked + .label {
  background: #fff;
  color: blue;
}

.panel {
  display: none;
  padding: 20px 30px 100px;
  background: #fff;
}

.input:checked + .label + .panel {
  display: block;
}

/* agenda */
.agenda-timing {
  font-weight: 700;
  font-size: 1.2rem;
}
.text-danger {
  color: #cc0a2f !important;
}
.agenda-bg-img {
  background: url("../Assets/BG\ Agenda\ Extended-02.png");
  background-size: cover;
  background-position: left;
  background-repeat: no-repeat;
  width: 100vw;
}

.agenda-speakers {
  display: none;
}
.agendacontainer {
  display: flex;
  align-items: center;
  height: fit-content;
  padding: 2% 0 0% 25%;
  justify-content: space-around;
}
.agendaleft-img {
  transform: rotate(-90deg);
  height: 20%;
  width: 390px;
}
.agenda {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.agenda-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}
.agenda-col h2,
.agenda-col h3 {
  text-align: center;
}
.agenda-col p {
  text-align: center;
  margin: 10px 0;
}
.agendaimage {
  width: 100%;
  height: auto;
}
.agendarow {
  display: flex;
  flex-direction: row;
}
.agendarow > .agendacolumn {
  flex: 1;
  padding: 0px;
  margin: 0 1%;
}

/* bengaluru section */
.bengaluru-section {
  /* background: url("https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/vmware%2FUdaipur.png?alt=media"); */
  background: url("../Assets/udaipur1.png");

  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  width: 100vw;
  height: 110vh;
  position: relative;
}

.outline-text {
  padding-top: 5%;
  font-size: 12rem; /* Adjust the font size as needed */
  color: transparent;
  font-family: auto;
  -webkit-text-stroke: 2px red;
  text-transform: uppercase; /* Optional: Makes the text uppercase */
}

.bengaluru-section-text {
  /* word-wrap: break-word;
  width: 45%;
  font-weight: 600;
  font-size: 1.5rem;
  margin: auto;
  position: absolute;
  right: 0;
  backdrop-filter: blur(4px); */
  word-wrap: break-word;
  width: 45%;
  font-weight: 600;
  font-size: 1.5rem;
  margin: auto;
  position: absolute;
  right: 0;
  border-radius: 21px;
  background: linear-gradient(45deg, #ffffffb0 12%, #e6e6e69c 50%);
  box-shadow: 20px 20px 99px #d9d9d9, -20px -20px 99px #ffffff;
}

/* harness */
.harness {
  position: relative;
}
.harness-container {
  background: url("https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/vmware%2FHarness.png?alt=media");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  width: 100vw;
  padding: 5% 10%;
  height: 100vh;
}
/* contact us */
.contact {
  margin-top: 20%;
  position: relative;
}
li::marker {
  color: red;
}

.harness-container-right-outer {
  padding: 5%;
}
.contact-container {
  padding: 0% 5% 12% 5%;
}

.contact-container a {
  color: #000 !important;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
}
.svg {
  position: absolute;
  bottom: -1.39%;
  left: 0;
  right: 0;
}

@media (min-width: 600px) {
  .panel {
    order: 99;
  }
}

@media (max-width: 768px) {
  .hero-content {
    width: 80%;
    padding: 15px;
  }

  .hero-content.first,
  .hero-content.second {
    left: 5%;
    top: 10%;
  }
}

@media (min-width: 600px) {
  .label {
    width: auto;
  }
}

@media only screen and (max-width: 979px) {
  .ag-courses_item {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }
  .ag-courses-item_title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }
}
/* @media only screen and (max-width: 639px) {
 
  
} */

@media (max-width: 500px) {
  .hero-container {
    background: url("https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/vmware%2FPhone%2F1.png?alt=media");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .overview {
    padding: 1px 0;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/vmware%2FPhone%2F2.png?alt=media);
    background-position: top;
    height: 100%;
    position: relative;
  }

  .overview .logo {
    display: flex !important;
    margin-top: 20%;
    justify-content: center;
    align-items: center;
  }
  .overview-header {
    font-size: 2rem;
    padding: 2%;
    margin-top: 5%;
  }
  .overview-text {
    font-size: 1.1rem !important;
    text-align: center !important;
    padding: 2%;
  }
  .overview-card {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .relax-container {
    background: url("https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/vmware%2FPhone%2F3.png?alt=media");
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    width: 100vw;
    height: 100%;
  }
  .relax-header-text h1 {
    font-size: 2rem !important;
  }
  .ag-courses_box {
    padding: 20px 0;
  }
  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .ag-courses-item_title {
    min-height: 72px;
    line-height: 1;
  }
  .ag-courses-item-title-text {
    font-size: 1.5rem !important;
  }
  .ag-courses-item_link {
    padding: 22px 40px;
  }
  .ag-courses-item_date-box {
    font-size: 1.1rem;
  }
  .agenda-bg-img {
    background: none;
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    width: 100vw;
  }
  .agendalogo-mob{
    display: block !important;
    padding: 5%;
  }
  .agendacontainer {
    /* display: none; */
    padding: 2% 0 0% 0%;
  }
  .outline-text {
    font-size: 4rem;
    padding-top: 18%;
  }
  .bengaluru-section {
    background-image: url("https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/vmware%2FPhone%2F7.png?alt=media");
    height: 100vh;
  }
  .bengaluru-section-text {
    width: 100%;
    font-size: 1.2rem;
    font-weight: 500;
    top: 34%;
  }
  .harness-container {
    background: url("https://firebasestorage.googleapis.com/v0/b/tieyoung-e964a.appspot.com/o/vmware%2FPhone%2F8.png?alt=media");
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    width: 100vw;
    padding: 5% 10%;
    height: 100vh;
  }
  .harness-header {
    font-size: 2rem !important;
  }
  .harness-subtext {
    font-size: 1.1rem !important;
  }
  .harness-faq ul li {
    font-size: 1.1rem !important;
  }
  .harness-container-right-outer {
    margin-top: 0 !important;
  }
  .harness-container-right-outer h1 {
    font-size: 2rem;
  }
  .contact {
    margin-top: 230%;
  }

  .contact-container > div > img {
    width: 90% !important;
  }
  .contact-container > div > h2 {
    font-size: 2rem !important;
  }
  .contact-container > div > div > p {
    font-size: 1.1rem !important;
  }
  .contact-container > h2 {
    font-size: 2rem !important;
  }
  .contact-container h3,
  a {
    font-size: 1.1rem !important;
  }
  .contactsvg > svg {
    width: 400px !important;
    bottom: -61px !important;
    display: none !important;
    
  }
  .contact-container {
    padding: 0% 5% 30% 5%;
  }
}

/* @media (max-width: 768px) {
  .overview-header {
    font-size: 3rem;
  }

  .overview-left,
  .overview-right {
    width: 100%;
    margin: 0;
    padding: 10px;
  }
} */
