@font-face {
  font-family: "Poppins";
  src: url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
  font-style: italic !important;
}
body {
  margin: 0;
  padding: 0;
  /* background-color: #17a2b8; */
  width: 100vw;
  height: 100vh;
}
.submit-btns {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.poppins{
  font-family: "Poppins", sans-serif;

}

div:where(.swal2-container) button:where(.swal2-styled):where(.swal2-confirm){
  background-color: #cc0a2f !important;
  outline: none !important;
}
input {
  font-family: "Poppins", sans-serif;
}

#login{
  /* background-image: ; */
  background-color: #290865;
}

#login-column{

}
#login .container #login-row #login-column #login-box {
  margin-top: 30%;
  max-width: 600px;
  border-radius: 15px;  
  font-family: Poppins;
}
#login .container #login-row #login-column #login-box #login-form {
  padding: 20px;
}

#login .container #login-row #login-column #login-box #register-form {
  padding: 20px;
}
#login
  .container
  #login-row
  #login-column
  #login-box
  #login-form
  #register-link {
  /* margin-top: -85px; */
}

.active-tab {
  padding: 1% 5%;
  width: 100%;
  background-color: transparent;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.5s;
  font-weight: 400;
  font-size: 17px;
  border: 1px solid #fff;
  text-transform: uppercase;
  z-index: 1;
  color: #fff;
}

.active-tab::before,
.active-tab::after {
  content: "";
  display: block;
  width: 50px;
  height: 50px;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 50%;
  z-index: -1;
  background-color: #fff;
  transition: 1s ease;
  outline: none !important;
}

.active-tab::before {
  top: -1em;
  left: -1em;
}

.active-tab::after {
  left: calc(100% + 1em);
  top: calc(100% + 1em);
}

.active-tab:hover::before,
.active-tab:hover::after {
  height: 410px;
  width: 410px;
}

.active-tab:hover {
  /* color: rgb(10, 25, 30); */
  color: #cc0a2f !important;
}

.active-tab:active {
  filter: brightness(0.8);
}

#login-column{
  position: absolute;
  top:19%;
  left: 44%;
}

@media (max-width: 500px) {

#login-column{
  position: absolute;
  top:25%;
  left: 0%;
  background-color: #290865;
}
}
